<template>

    <!-- Table Container Card -->
    <b-card
      no-body
    >
    <role-list-add-new
      v-model="isAddNewRoleSidebarActive"
      :role-data="roleData"
      :clear-role-data="clearRoleData"
      :permissions-data="getPermissions"
      @add-role="addRole"
      @update-role="updateRole"
    />

      <div class="m-2">
  
        <!-- Table Top -->
        <b-row>
  
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
                variant="primary"
                @click="isAddNewRoleSidebarActive = true"
                class="mr-1"
            >
                Add Role
            </b-button>

            <!-- <b-button
              variant="outline-primary"
              class="mr-1"
              @click="genRoleReport"
            >
              Export to *.Excel
            </b-button> -->
          </b-col>
  
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
  
      </div>
  
      <b-table
        ref="refRoleListTable"
        :items="getRoles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
  
        <!-- Column: Id -->
        <template #cell(id)="data">
          <!-- <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
            class="font-weight-bold"
          > -->
            <!-- #{{ data.value }} -->
            {{ data.index+1 }}
          <!-- </b-link> -->
        </template>

        <template #cell(name)="data">
            <b-link
                class="font-weight-bolder"
            > 
                {{ data.item.name.substring(0,1).toUpperCase()+data.item.name.substring(1) }}
            </b-link>
        </template>

          <template #cell(_createdAt)="data">
          <div class="text-nowrap">
            <span class="font-weight-bold align-text-top text-capitalize">{{ data.item._createdAt }}</span>
          </div>
          </template>

          <template #cell(users)="data">
            <div class="text-nowrap">
              <b-badge
                pill
                :variant="`primary`"
                v-for="user in data.item.users"
                :key="user"
                class="text-capitalize mr-1 cursor-pointer"
                @click="router.push({ name: 'settings-user-list', params: {  search: user } })"
              >
                {{ user }}
              </b-badge>
            </div>
          </template>

          <!-- Column: permissions -->
            <template #cell(permissions)="data">
                <span class="text-nowrap">
                
                </span>
                <b-link
                @click="editRole(data.item)"
                class="font-weight-bold"
                >
                {{ data.item.permissions.length }} Permissions
                </b-link>
            </template>
  
        <!-- Column: Actions -->
        <template #cell(actions)="data">
  
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              v-if="data.item.role !== 'admin'"
              variant="link"
              toggle-class=""
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
            
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              
              <b-dropdown-item @click="router.push({ name: 'settings-user-list', params: {  role: data.item.name } })">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Add User</span>
                </b-dropdown-item>
              <b-dropdown-item @click="editRole(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="delRole(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
  
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
  
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries </span>
            
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
  
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRoles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
  
          </b-col>
  
        </b-row>
      </div>
    </b-card>
  
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BFormTextarea, BFormDatepicker, BFormTimepicker, BForm, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BAlert,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted } from '@vue/composition-api'
  import store from '@/store'
  import useRoleList from './useRoleList'
  import flatPickr from 'vue-flatpickr-component'
  
  import roleStoreModule from '../roleStoreModule'
  import router from '@/router'
  import RoleListAddNew from '../role-add-new/RoleListAddNew.vue'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BFormTextarea,
      BFormDatepicker,
      BFormTimepicker,
      BForm,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BFormGroup,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BTooltip,
      BAlert,
      flatPickr,
      vSelect,
      RoleListAddNew,
    },
    data() {
      return {
        router
      }
    },
    setup() {
  
      const SETTINGS_ROLE_STORE_MODULE_NAME = 'settings-role'
  
      // Register module
      if (!store.hasModule(SETTINGS_ROLE_STORE_MODULE_NAME)) store.registerModule(SETTINGS_ROLE_STORE_MODULE_NAME, roleStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(SETTINGS_ROLE_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_ROLE_STORE_MODULE_NAME)
      })

      const statusOptions = [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false }
      ]

  
      const roleOptions = [
        { label: 'Admin', value: 'admin' },
        { label: 'Role', value: 'role' },
      ]
  
      const {
        fetchRoles,
        fetchPermissions,
        approveRoles,
        getRoles,
        getPermissions,
        tableColumns,
        perPage,
        currentPage,
        totalRoles,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refRoleListTable,
  
        refetchData,
        resolveRoleStatusVariant,
        resolveRoleTypeVariant,
        resolveTypeRoleIcon,
        isAddNewRoleSidebarActive,
        addRole,
        updateRole,
        delRole,
        clearRoleData,
        editRole,
        roleData
        
      } = useRoleList()
  
      fetchRoles()

      fetchPermissions()
      
      
      return {
        approveRoles,
        getRoles,
        getPermissions,
        tableColumns,
        perPage,
        currentPage,
        totalRoles,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refRoleListTable,
  
        refetchData,
        roleOptions,
        statusOptions,
        resolveRoleStatusVariant,
        resolveRoleTypeVariant,
        resolveTypeRoleIcon,
        isAddNewRoleSidebarActive,
        addRole,
        updateRole,
        delRole,
        clearRoleData,
        editRole,
        roleData,
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  