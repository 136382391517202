<template>
    <b-sidebar
      id="add-new-role-sidebar"
      :visible="isAddNewRoleSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-role-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ roleLocal.id ? 'Update': 'Add' }} Role
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
              
            <!-- name -->
            <b-form-group
                label="Role name"
                label-for="name"
            >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="roleLocal.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Role name"
                   />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- Role Access -->
            <validation-provider
              #default="validationContext"
              name="Role Permissions"
              rules="required"
            >
              <b-form-group
                  label="Role Permissions"
                  label-for="role-access"
                  :state="getValidationState(validationContext)"
                >
                <v-select
                    v-model="roleLocal.permissions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="permissionsData"
                    label="resource"
                    :close-on-select="false"
                    multiple
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BRow, BCol, BSidebar, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BFormCheckbox, BFormDatepicker, BFormTextarea, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref, toRefs, } from '@vue/composition-api'
  import { required, } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import useMembersList from '../../../apps/membership/members-list/useMemberList'
  import useRolesListAddNew from './useRoleListAddNew'

  
  
  export default {
    components: {
      BRow,
      BCol,
      BSidebar,
      BForm,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BFormCheckbox,
      BFormDatepicker,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewRoleSidebarActive',
      event: 'update:is-add-new-role-sidebar-active',
    },
    props: {
      isAddNewRoleSidebarActive: {
        type: Boolean,
        required: true,
      },
      roleData: {
        type: Object
      },
      clearRoleData: {
        type: Function,
        required: true,
      },
      permissionsData: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        required,
      }
    },
    setup(props, { emit }) {
      
      const clearFormData = ref(null)

      const { 
        roleLocal,
        resetRoleLocal,
        onSubmit,
      } = useRolesListAddNew(toRefs(props), clearFormData, emit)

      const { getAllMembers } = useMembersList()
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetRoleLocal, props.clearRoleData)

      clearFormData.value = clearForm
  
      return {
        roleLocal,
        onSubmit,
        resetForm,
        refFormObserver,
        getValidationState,
        getAllMembers,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-role-sidebar {
    .vs__dropdown-menu {
      max-height: 500px !important;
    }
  }

  </style>
  