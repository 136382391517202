import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useRoleListAddNew(props, clearForm, emit) {
  // ------------------------------------------------
  // roleLocal
  // ------------------------------------------------
  const roleLocal = ref(JSON.parse(JSON.stringify(props.roleData.value)))
  const resetRoleLocal = () => {
    roleLocal.value = JSON.parse(JSON.stringify(props.roleData.value))
  }
  watch(props.roleData, () => {
    resetRoleLocal()
  })

  // ------------------------------------------------
  // isAddNewRoleSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isAddNewRoleSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
//   const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  const onSubmit = () => {
    const roleData = JSON.parse(JSON.stringify(roleLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.roleData.value.id) emit('update-role', roleData.value)
    else emit('add-role', roleData.value)

    // Close sidebar
    // emit('update:is-add-new-role-sidebar-active', false)
  }

//   const rolesOptions = computed(() => store.state.calendar.roles)

  /* eslint-enable global-require */

  return {
    roleLocal,
    resetRoleLocal,
    // calendarOptions,

    onSubmit,
  }
}
